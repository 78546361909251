<template>
  <div class="container">
    <img  class="hidden-sm-and-down" style="width: 100%" src="../../../assets/mock/us/组 7473.png" alt="" />
    <img  class="hidden-md-and-up" style="width: 100%" src="../../../assets/mock/us/组 11467.png" alt="" />
    <div>
      <div class="top">
        <h3>联系我们</h3>
        <span></span>
        <p>CONTACT US</p>
      </div>
      <!-- contentHeader -->
      <div class="contentHeader warp">
        <div style="margin: 1.875rem 0">
          <p>
            宇起•智能锁隶属于宇起数字科技（上海）有限公司，始创于2017年，宇起专注于物联网平台和NB-IOT等移动通讯技术，将人力、设备、能源、交通、服务、金融等运营数据，与自有信息技术系统互联互通，形成一个高效低耗的城市级数据生态的创新型科技中心。宇起已研发出智能指纹铁质柜锁、智能指纹木质柜锁、智能指纹展示柜锁等系列产品。
          </p>
          <p>
            作为少数具备智能锁一体化自主研发实力技术、自主掌握指纹锁核心算法的驱动型企业，宇起拥有十余项技术专利，安全技术、无线网技术、移动通讯等物联网技术，实现硬件与软件、系统平台与手机客户端APP应用集成，组建智能物联网平台。
          </p>
          <p>
            宇起保证用户财产安全的技术基础上，同时不断优化用户体验，提升产品的便捷性、美观度、简易型，宇起一直相信精品永远属于那些将技术、便捷、简易、美观做到极致的产品。
          </p>
          <p>
            “出品即精品，生活即智能”是宇起一直铭记在心的诉求，始终以艺术品的审美标准，以超越时代的创新科技，将智能门锁的安全研发到极致，成就“智能锁之王”，让用户的安全感无处不在，获得了众多用户的喜爱和好评。
          </p>
          <p>
            宇起目前已实现蓬勃式发展，迅速崛起腾飞。宇起已逐步深入到智能楼宇、智慧社区、智慧物业、智慧医疗等多个板块。宇起力求将智能设备开发与场景化应用密切结合，
            满足社会大众的智慧生活、防火防盗需求，不遗余力地将自身打造为整个智慧城市的强力引擎，并基于客户需求持续创新，
            为各行业用户提供安全便捷的智能软硬件解决方案。进一步打通完善从生产、渠道、营销，到服务的完整闭环链条，并在丰富的智能安防产品的基础上，为用户提供全套省心的安防服务
          </p>
          <p>
            现代传播手段十分强调树立企业的品牌形象，在众多的市场竞争中，能否成为品牌，不仅取决于内在质量，还取决于能否通过广告宣传及企业视觉形象建立起独一无二的品牌形象。为此，我们对宇起形象、定位等进行全面设计并给予其内涵。
          </p>
        </div>
        <div class="banners">
          <img
            style="width: 100%"
            src="../../../assets/mock/us/矩形 2515.png"
            alt=""
          />
        </div>
        <ul>
          <li>
            <img src="../../../assets/mock/us/组 1144.png" alt="" /><br />
            <span>高端品质</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1143.png" alt="" /><br />
            <span>智能物联</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1142.png" alt="" /><br />
            <span>创新科技</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1141.png" alt="" /><br />
            <span>隐私安全</span>
          </li>
        </ul>
      </div>
      <!-- us -->
      <div class="top">
        <h3>公司文化</h3>
        <span></span>
        <p>COMPANY CULTURE</p>
      </div>
      <!-- COMPANY CULTURE -->
      <div class="warp culture">
        <ul>
          <li style="background-color: #fff8f2">
            <img src="../../../assets/mock/us/矩形 2829.png" alt="" />
            <div>
              <h5><span>01</span>品牌愿景</h5>
              <p>Brand Vision</p>
              <p>成为国际领先的物联网行业综合服务商。</p>
            </div>
          </li>
          <li style="background-color: #f0faff">
            <img src="../../../assets/mock/us/蒙版组 12.png" alt="" />
            <div>
              <h5><span>02</span>品牌理念</h5>
              <p>Brand concept</p>
              <p>宇你一起、享受智能生活。</p>
            </div>
          </li>
          <li style="background-color: #fff8f2">
            <img src="../../../assets/mock/us/蒙版组 13.png" alt="" />
            <div>
              <h5><span>03</span>品牌愿景</h5>
              <p>Brand connotation</p>
              <p>保持产品创新，不断以技术领跑，提高产品换代能力。</p>
            </div>
          </li>
          <li style="background-color: #f0faff">
            <img src="../../../assets/mock/us/蒙版组 14.png" alt="" />
            <div>
              <h5><span>04</span>品牌愿景</h5>
              <p>Brand Vision</p>
              <p>数字科技的简约美。</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  .top {
    text-align: center;
    h3 {
      margin-top: 1.25rem;
    }
    span {
      width: 1.25rem;
      height: 3px;
      display: inline-block;
      background-color: #3cb59e;
    }
  }
  .contentHeader {
    p {
      text-indent: 2rem;
      font-size: 0.875rem;
      line-height: 2;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 1.875rem auto;
      li {
        text-align: center;
        img {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
  .culture {
    overflow: hidden;
    margin-bottom: 3.125rem;
    ul {
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      li {
        width: 17rem;
        margin-bottom: 1.25rem;
        img {
          width: 100%;
        }
        overflow: hidden;
        text-align: center;
        p {
          margin: 0;
          font-size: 0.75rem;
          padding: 5px 0;
          color: #777777;
        }
        span {
          font-size: 21px;
          font-family: Gotham-Bold;
          line-height: 25px;
          color: #3cb59e;
        }
      }
    }
  }
}
</style>
